/* tslint:disable */
import {Injectable} from '@angular/core';
import {HttpClient, HttpRequest, HttpResponse, HttpHeaders} from '@angular/common/http';
import {BaseService as __BaseService} from '../base-service';
import {ApiLogisticaConfiguration as __Configuration} from '../api-logistica-configuration';
import {StrictHttpResponse as __StrictHttpResponse} from '../strict-http-response';
import {Observable as __Observable} from 'rxjs';
import {map as __map, filter as __filter} from 'rxjs/operators';

import {DashboardData} from '../models/dashboard-data';
import {ResumeGroupQueryInfo} from '../models/resume-group-query-info';
import {QueryResultVProductoVigenciaCuraduriaLite} from '../models/query-result-vproducto-vigencia-curaduria-lite';
import {QueryInfo} from '../models/query-info';
@Injectable({
  providedIn: 'root',
})
class ProcesosL10AtenderPendienteVigenciaCuraduriaService extends __BaseService {
  static readonly vProductoVigenciaCuraduriaObtenerAtenderVigenciaDeCuraduriaDashboardPath =
    '/AtenderVigenciaDeCuraduria/Dashboard';
  static readonly vProductoVigenciaCuraduriaObtenerListadoProductosPath =
    '/AtenderVigenciaDeCuraduria/ListaDeProductos';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * ObtenerAtenderVigenciaDeCuraduriaDashboard vProductoVigenciaCuraduria
   * @param info undefined
   * @return OK
   */
  vProductoVigenciaCuraduriaObtenerAtenderVigenciaDeCuraduriaDashboardResponse(
    info: ResumeGroupQueryInfo,
  ): __Observable<__StrictHttpResponse<DashboardData>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = info;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/AtenderVigenciaDeCuraduria/Dashboard`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DashboardData>;
      }),
    );
  }
  /**
   * ObtenerAtenderVigenciaDeCuraduriaDashboard vProductoVigenciaCuraduria
   * @param info undefined
   * @return OK
   */
  vProductoVigenciaCuraduriaObtenerAtenderVigenciaDeCuraduriaDashboard(
    info: ResumeGroupQueryInfo,
  ): __Observable<DashboardData> {
    return this.vProductoVigenciaCuraduriaObtenerAtenderVigenciaDeCuraduriaDashboardResponse(
      info,
    ).pipe(__map((_r) => _r.body as DashboardData));
  }

  /**
   * ObtenerListadoProductos vProductoVigenciaCuraduria
   * @param info undefined
   * @return OK
   */
  vProductoVigenciaCuraduriaObtenerListadoProductosResponse(
    info: QueryInfo,
  ): __Observable<__StrictHttpResponse<QueryResultVProductoVigenciaCuraduriaLite>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = info;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/AtenderVigenciaDeCuraduria/ListaDeProductos`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<QueryResultVProductoVigenciaCuraduriaLite>;
      }),
    );
  }
  /**
   * ObtenerListadoProductos vProductoVigenciaCuraduria
   * @param info undefined
   * @return OK
   */
  vProductoVigenciaCuraduriaObtenerListadoProductos(
    info: QueryInfo,
  ): __Observable<QueryResultVProductoVigenciaCuraduriaLite> {
    return this.vProductoVigenciaCuraduriaObtenerListadoProductosResponse(info).pipe(
      __map((_r) => _r.body as QueryResultVProductoVigenciaCuraduriaLite),
    );
  }
}

module ProcesosL10AtenderPendienteVigenciaCuraduriaService {}

export {ProcesosL10AtenderPendienteVigenciaCuraduriaService};
