/* tslint:disable */
import {Injectable} from '@angular/core';
import {HttpClient, HttpRequest, HttpResponse, HttpHeaders} from '@angular/common/http';
import {BaseService as __BaseService} from '../base-service';
import {ApiLogisticaConfiguration as __Configuration} from '../api-logistica-configuration';
import {StrictHttpResponse as __StrictHttpResponse} from '../strict-http-response';
import {Observable as __Observable} from 'rxjs';
import {map as __map, filter as __filter} from 'rxjs/operators';

import {TpPedido} from '../models/tp-pedido';
import {QueryResultTpPedido} from '../models/query-result-tp-pedido';
import {QueryInfo} from '../models/query-info';
import {TpPedidoVD} from '../models/tp-pedido-vd';
import {QueryResultTpPedidoVD} from '../models/query-result-tp-pedido-vd';
@Injectable({
  providedIn: 'root',
})
class ProcesosL05TramitarPedidoService extends __BaseService {
  static readonly tpPedidoObtenerPath = '/tpPedido';
  static readonly tpPedidoGuardarOActualizarPath = '/tpPedido';
  static readonly tpPedidoQueryResultPath = '/tpPedido';
  static readonly tpPedidoDesactivarPath = '/tpPedido';
  static readonly tpPedidoVDObtenerPath = '/tpPedidoVD';
  static readonly tpPedidoVDGuardarOActualizarPath = '/tpPedidoVD';
  static readonly tpPedidoVDQueryResultPath = '/tpPedidoVD';
  static readonly tpPedidoVDDesactivarPath = '/tpPedidoVD';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Obtener pPedido por su idpPedido
   * @param idtpPedido Identificador de pPedido
   * @return OK
   */
  tpPedidoObtenerResponse(idtpPedido: string): __Observable<__StrictHttpResponse<TpPedido>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (idtpPedido != null) __params = __params.set('idtpPedido', idtpPedido.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/tpPedido`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TpPedido>;
      }),
    );
  }
  /**
   * Obtener pPedido por su idpPedido
   * @param idtpPedido Identificador de pPedido
   * @return OK
   */
  tpPedidoObtener(idtpPedido: string): __Observable<TpPedido> {
    return this.tpPedidoObtenerResponse(idtpPedido).pipe(__map((_r) => _r.body as TpPedido));
  }

  /**
   * Guardar o actualizar una dirección de empresa.
   * @param tpPedido Dirección de empresa.
   * @return OK
   */
  tpPedidoGuardarOActualizarResponse(
    tpPedido: TpPedido,
  ): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = tpPedido;
    let req = new HttpRequest<any>('PUT', this.rootUrl + `/tpPedido`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'text',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      }),
    );
  }
  /**
   * Guardar o actualizar una dirección de empresa.
   * @param tpPedido Dirección de empresa.
   * @return OK
   */
  tpPedidoGuardarOActualizar(tpPedido: TpPedido): __Observable<string> {
    return this.tpPedidoGuardarOActualizarResponse(tpPedido).pipe(__map((_r) => _r.body as string));
  }

  /**
   * Obtener lista de pPedido
   * @param info Objeto con filtros y ordenamiento
   * @return OK
   */
  tpPedidoQueryResultResponse(
    info: QueryInfo,
  ): __Observable<__StrictHttpResponse<QueryResultTpPedido>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = info;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/tpPedido`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<QueryResultTpPedido>;
      }),
    );
  }
  /**
   * Obtener lista de pPedido
   * @param info Objeto con filtros y ordenamiento
   * @return OK
   */
  tpPedidoQueryResult(info: QueryInfo): __Observable<QueryResultTpPedido> {
    return this.tpPedidoQueryResultResponse(info).pipe(
      __map((_r) => _r.body as QueryResultTpPedido),
    );
  }

  /**
   * Desactivar un pPedido.
   * @param idtpPedido Identificador de elemento a desactivar.
   * @return OK
   */
  tpPedidoDesactivarResponse(idtpPedido: string): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (idtpPedido != null) __params = __params.set('idtpPedido', idtpPedido.toString());
    let req = new HttpRequest<any>('DELETE', this.rootUrl + `/tpPedido`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'text',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      }),
    );
  }
  /**
   * Desactivar un pPedido.
   * @param idtpPedido Identificador de elemento a desactivar.
   * @return OK
   */
  tpPedidoDesactivar(idtpPedido: string): __Observable<string> {
    return this.tpPedidoDesactivarResponse(idtpPedido).pipe(__map((_r) => _r.body as string));
  }

  /**
   * Obtener un tpPedidoVD por su idtpPedidoVD
   * @param idtpPedidoVD identificador del tpPedidoVD
   * @return OK
   */
  tpPedidoVDObtenerResponse(idtpPedidoVD: string): __Observable<__StrictHttpResponse<TpPedidoVD>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (idtpPedidoVD != null) __params = __params.set('idtpPedidoVD', idtpPedidoVD.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/tpPedidoVD`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TpPedidoVD>;
      }),
    );
  }
  /**
   * Obtener un tpPedidoVD por su idtpPedidoVD
   * @param idtpPedidoVD identificador del tpPedidoVD
   * @return OK
   */
  tpPedidoVDObtener(idtpPedidoVD: string): __Observable<TpPedidoVD> {
    return this.tpPedidoVDObtenerResponse(idtpPedidoVD).pipe(__map((_r) => _r.body as TpPedidoVD));
  }

  /**
   * Guardar o actualizar un tpPedidoVD
   * @param tpPedidoVD tpPedidoVD a actualizar o guardar
   * @return OK
   */
  tpPedidoVDGuardarOActualizarResponse(
    tpPedidoVD: TpPedidoVD,
  ): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = tpPedidoVD;
    let req = new HttpRequest<any>('PUT', this.rootUrl + `/tpPedidoVD`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'text',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      }),
    );
  }
  /**
   * Guardar o actualizar un tpPedidoVD
   * @param tpPedidoVD tpPedidoVD a actualizar o guardar
   * @return OK
   */
  tpPedidoVDGuardarOActualizar(tpPedidoVD: TpPedidoVD): __Observable<string> {
    return this.tpPedidoVDGuardarOActualizarResponse(tpPedidoVD).pipe(
      __map((_r) => _r.body as string),
    );
  }

  /**
   * Obtener lista de tpPedidoVD.
   * @param info Objeto de tipo QueryInfo para obtener la lista.
   * @return OK
   */
  tpPedidoVDQueryResultResponse(
    info: QueryInfo,
  ): __Observable<__StrictHttpResponse<QueryResultTpPedidoVD>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = info;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/tpPedidoVD`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<QueryResultTpPedidoVD>;
      }),
    );
  }
  /**
   * Obtener lista de tpPedidoVD.
   * @param info Objeto de tipo QueryInfo para obtener la lista.
   * @return OK
   */
  tpPedidoVDQueryResult(info: QueryInfo): __Observable<QueryResultTpPedidoVD> {
    return this.tpPedidoVDQueryResultResponse(info).pipe(
      __map((_r) => _r.body as QueryResultTpPedidoVD),
    );
  }

  /**
   * Desactivar un tpPedidoVD.
   * @param idtpPedidoVD Identificador de tpPedidoVD a ser desactivado.
   * @return OK
   */
  tpPedidoVDDesactivarResponse(idtpPedidoVD: string): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (idtpPedidoVD != null) __params = __params.set('idtpPedidoVD', idtpPedidoVD.toString());
    let req = new HttpRequest<any>('DELETE', this.rootUrl + `/tpPedidoVD`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'text',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      }),
    );
  }
  /**
   * Desactivar un tpPedidoVD.
   * @param idtpPedidoVD Identificador de tpPedidoVD a ser desactivado.
   * @return OK
   */
  tpPedidoVDDesactivar(idtpPedidoVD: string): __Observable<string> {
    return this.tpPedidoVDDesactivarResponse(idtpPedidoVD).pipe(__map((_r) => _r.body as string));
  }
}

module ProcesosL05TramitarPedidoService {}

export {ProcesosL05TramitarPedidoService};
