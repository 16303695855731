<div class="modal-loading">
  <div class="pop">
    <div class="title">
      <div class="title-h1">PROQUIFA NET</div>
    </div>
    <div class="body">
      <div #containerElement style="width: 100px; height: 100px;"></div>
      <p class="footer">{{ 'common.loadingText' | translate }}</p>
    </div>
  </div>
</div>
