import {createAction, props} from '@ngrx/store';

import {AppSettings, Language} from '@appModels/store/settings/settings.model';
import {buildingStringActionType} from '@appUtil/strings';
const typeReducer = 'Settings';

export const CHANGE_LANGUAGE = createAction(
  '[Settings] Change Language',
  props<{language: Language}>(),
);
export const SET_APPLICATION_CONFIGURATION = createAction(
  '[Settings] Set application configuration',
  props<{appSettings: AppSettings}>(),
);
export const SET_INITIALIZATION_COMPLETE = createAction(
  '[Settings] Set application initialization complete',
);
export const SET_BROWSER = createAction(
  '[Settings] Set browser',
  props<{currentBrowser: string}>(),
);

export const SET_LOAD_CONFIGURATION_PRORATED_ACTIVE = createAction(
  buildingStringActionType(typeReducer, 'Set load configuration prorated active'),
);
export const SET_SUCCESS_CONFIGURATION_PRORATED_ACTIVE = createAction(
  buildingStringActionType(typeReducer, 'Set success configuration prorated active'),
  props<{value: boolean}>(),
);
