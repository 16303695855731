/* tslint:disable */
import {Injectable} from '@angular/core';
import {HttpClient, HttpRequest, HttpResponse, HttpHeaders} from '@angular/common/http';
import {BaseService as __BaseService} from '../base-service';
import {ApiCatalogosConfiguration as __Configuration} from '../api-catalogos-configuration';
import {StrictHttpResponse as __StrictHttpResponse} from '../strict-http-response';
import {Observable as __Observable} from 'rxjs';
import {map as __map, filter as __filter} from 'rxjs/operators';

import {Producto} from '../models/producto';
import {QueryResultProducto} from '../models/query-result-producto';
import {QueryInfo} from '../models/query-info';
import {ProductoClienteWebDetalle} from '../models/producto-cliente-web-detalle';
import {QueryResultProductoClienteWeb} from '../models/query-result-producto-cliente-web';
import {QueryResultProductoClienteWebDetalle} from '../models/query-result-producto-cliente-web-detalle';
import {QueryResultVPrecioListaProducto} from '../models/query-result-vprecio-lista-producto';
import {VProducto} from '../models/vproducto';
import {QueryResultVProducto} from '../models/query-result-vproducto';
import {VProductoClienteVDDetalle} from '../models/vproducto-cliente-vddetalle';
import {QueryResultVProductoClienteVD} from '../models/query-result-vproducto-cliente-vd';
import {QueryResultVProductoClienteVDDetalle} from '../models/query-result-vproducto-cliente-vddetalle';
import {VProductoClienteWebDetalle} from '../models/vproducto-cliente-web-detalle';
import {QueryResultVProductoClienteWeb} from '../models/query-result-vproducto-cliente-web';
import {QueryResultVProductoClienteWebDetalle} from '../models/query-result-vproducto-cliente-web-detalle';
@Injectable({
  providedIn: 'root',
})
class ConfiguracionProductosService extends __BaseService {
  static readonly ProductoObtenerPath = '/Producto';
  static readonly ProductoGuardarOActualizarPath = '/Producto';
  static readonly ProductoQueryResultPath = '/Producto';
  static readonly ProductoDesactivarPath = '/Producto';
  static readonly ProductoClienteWebObtenerPath = '/ProductoClienteWeb';
  static readonly ProductoClienteWebQueryResultPath = '/ProductoClienteWeb';
  static readonly ProductoClienteWebQueryResult_1Path = '/ProductoClienteWebByCliente';
  static readonly vPrecioListaProductoQueryResultPath = '/vPrecioListaProducto';
  static readonly vProductoObtenerPath = '/vProducto';
  static readonly vProductoQueryResultPath = '/vProducto';
  static readonly vProductoClienteVDObtenerPath = '/vProductoClienteVD';
  static readonly vProductoClienteVDQueryResultPath = '/vProductoClienteVD';
  static readonly vProductoClienteVDVProductoClienteVDByClientePath =
    '/vProductoClienteVDByCliente';
  static readonly vProductoClienteWebObtenerPath = '/vProductoClienteWeb';
  static readonly vProductoClienteWebQueryResultPath = '/vProductoClienteWeb';
  static readonly vProductoClienteWebQueryResult_1Path = '/vProductoClienteWebByCliente';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Consultar registro de Producto
   * @param idProducto Identificador de Producto
   * @return OK
   */
  ProductoObtenerResponse(idProducto: string): __Observable<__StrictHttpResponse<Producto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (idProducto != null) __params = __params.set('idProducto', idProducto.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/Producto`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Producto>;
      }),
    );
  }
  /**
   * Consultar registro de Producto
   * @param idProducto Identificador de Producto
   * @return OK
   */
  ProductoObtener(idProducto: string): __Observable<Producto> {
    return this.ProductoObtenerResponse(idProducto).pipe(__map((_r) => _r.body as Producto));
  }

  /**
   * Guardar o actualizar Producto
   * @param params The `ConfiguracionProductosService.ProductoGuardarOActualizarParams` containing the following parameters:
   *
   * - `Producto`: Producto
   *
   * - `IdMovimiento`: Identificador de Movimiento
   *
   * @return OK
   */
  ProductoGuardarOActualizarResponse(
    params: ConfiguracionProductosService.ProductoGuardarOActualizarParams,
  ): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.Producto;
    if (params.IdMovimiento != null)
      __params = __params.set('IdMovimiento', params.IdMovimiento.toString());
    let req = new HttpRequest<any>('PUT', this.rootUrl + `/Producto`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'text',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      }),
    );
  }
  /**
   * Guardar o actualizar Producto
   * @param params The `ConfiguracionProductosService.ProductoGuardarOActualizarParams` containing the following parameters:
   *
   * - `Producto`: Producto
   *
   * - `IdMovimiento`: Identificador de Movimiento
   *
   * @return OK
   */
  ProductoGuardarOActualizar(
    params: ConfiguracionProductosService.ProductoGuardarOActualizarParams,
  ): __Observable<string> {
    return this.ProductoGuardarOActualizarResponse(params).pipe(__map((_r) => _r.body as string));
  }

  /**
   * Consultar lista paginada de Producto
   * @param info Objeto con filtros y ordenamiento
   * @return OK
   */
  ProductoQueryResultResponse(
    info: QueryInfo,
  ): __Observable<__StrictHttpResponse<QueryResultProducto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = info;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/Producto`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<QueryResultProducto>;
      }),
    );
  }
  /**
   * Consultar lista paginada de Producto
   * @param info Objeto con filtros y ordenamiento
   * @return OK
   */
  ProductoQueryResult(info: QueryInfo): __Observable<QueryResultProducto> {
    return this.ProductoQueryResultResponse(info).pipe(
      __map((_r) => _r.body as QueryResultProducto),
    );
  }

  /**
   * Desactivar registro de Producto
   * @param idProducto Identificador de registro de Producto
   * @return OK
   */
  ProductoDesactivarResponse(idProducto: string): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (idProducto != null) __params = __params.set('idProducto', idProducto.toString());
    let req = new HttpRequest<any>('DELETE', this.rootUrl + `/Producto`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'text',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      }),
    );
  }
  /**
   * Desactivar registro de Producto
   * @param idProducto Identificador de registro de Producto
   * @return OK
   */
  ProductoDesactivar(idProducto: string): __Observable<string> {
    return this.ProductoDesactivarResponse(idProducto).pipe(__map((_r) => _r.body as string));
  }

  /**
   * Obtener ProductoClienteWeb
   * @param params The `ConfiguracionProductosService.ProductoClienteWebObtenerParams` containing the following parameters:
   *
   * - `idProducto`:
   *
   * - `IdCliente`:
   *
   * - `IdDireccionEntrega`:
   *
   * @return OK
   */
  ProductoClienteWebObtenerResponse(
    params: ConfiguracionProductosService.ProductoClienteWebObtenerParams,
  ): __Observable<__StrictHttpResponse<ProductoClienteWebDetalle>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.idProducto != null)
      __params = __params.set('idProducto', params.idProducto.toString());
    if (params.IdCliente != null) __params = __params.set('IdCliente', params.IdCliente.toString());
    if (params.IdDireccionEntrega != null)
      __params = __params.set('IdDireccionEntrega', params.IdDireccionEntrega.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/ProductoClienteWeb`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProductoClienteWebDetalle>;
      }),
    );
  }
  /**
   * Obtener ProductoClienteWeb
   * @param params The `ConfiguracionProductosService.ProductoClienteWebObtenerParams` containing the following parameters:
   *
   * - `idProducto`:
   *
   * - `IdCliente`:
   *
   * - `IdDireccionEntrega`:
   *
   * @return OK
   */
  ProductoClienteWebObtener(
    params: ConfiguracionProductosService.ProductoClienteWebObtenerParams,
  ): __Observable<ProductoClienteWebDetalle> {
    return this.ProductoClienteWebObtenerResponse(params).pipe(
      __map((_r) => _r.body as ProductoClienteWebDetalle),
    );
  }

  /**
   * Consultar lista paginada de ProductoClienteWeb
   * @param info Filtros y ordenamientos
   * @return OK
   */
  ProductoClienteWebQueryResultResponse(
    info: QueryInfo,
  ): __Observable<__StrictHttpResponse<QueryResultProductoClienteWeb>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = info;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/ProductoClienteWeb`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<QueryResultProductoClienteWeb>;
      }),
    );
  }
  /**
   * Consultar lista paginada de ProductoClienteWeb
   * @param info Filtros y ordenamientos
   * @return OK
   */
  ProductoClienteWebQueryResult(info: QueryInfo): __Observable<QueryResultProductoClienteWeb> {
    return this.ProductoClienteWebQueryResultResponse(info).pipe(
      __map((_r) => _r.body as QueryResultProductoClienteWeb),
    );
  }

  /**
   * Consultar lista paginada de ProductoClienteWeb
   * @param params The `ConfiguracionProductosService.ProductoClienteWebQueryResult_1Params` containing the following parameters:
   *
   * - `info`: Filtros y ordenamientos
   *
   * - `IdDireccionEntrega`:
   *
   * - `IdCliente`:
   *
   * @return OK
   */
  ProductoClienteWebQueryResult_1Response(
    params: ConfiguracionProductosService.ProductoClienteWebQueryResult_1Params,
  ): __Observable<__StrictHttpResponse<QueryResultProductoClienteWebDetalle>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.info;
    if (params.IdDireccionEntrega != null)
      __params = __params.set('IdDireccionEntrega', params.IdDireccionEntrega.toString());
    if (params.IdCliente != null) __params = __params.set('IdCliente', params.IdCliente.toString());
    let req = new HttpRequest<any>('POST', this.rootUrl + `/ProductoClienteWebByCliente`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<QueryResultProductoClienteWebDetalle>;
      }),
    );
  }
  /**
   * Consultar lista paginada de ProductoClienteWeb
   * @param params The `ConfiguracionProductosService.ProductoClienteWebQueryResult_1Params` containing the following parameters:
   *
   * - `info`: Filtros y ordenamientos
   *
   * - `IdDireccionEntrega`:
   *
   * - `IdCliente`:
   *
   * @return OK
   */
  ProductoClienteWebQueryResult_1(
    params: ConfiguracionProductosService.ProductoClienteWebQueryResult_1Params,
  ): __Observable<QueryResultProductoClienteWebDetalle> {
    return this.ProductoClienteWebQueryResult_1Response(params).pipe(
      __map((_r) => _r.body as QueryResultProductoClienteWebDetalle),
    );
  }

  /**
   * QueryResult vPrecioListaProducto
   * @param info undefined
   * @return OK
   */
  vPrecioListaProductoQueryResultResponse(
    info: QueryInfo,
  ): __Observable<__StrictHttpResponse<QueryResultVPrecioListaProducto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = info;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/vPrecioListaProducto`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<QueryResultVPrecioListaProducto>;
      }),
    );
  }
  /**
   * QueryResult vPrecioListaProducto
   * @param info undefined
   * @return OK
   */
  vPrecioListaProductoQueryResult(info: QueryInfo): __Observable<QueryResultVPrecioListaProducto> {
    return this.vPrecioListaProductoQueryResultResponse(info).pipe(
      __map((_r) => _r.body as QueryResultVPrecioListaProducto),
    );
  }

  /**
   * Obtener vProducto
   * @param idProducto undefined
   * @return OK
   */
  vProductoObtenerResponse(idProducto: string): __Observable<__StrictHttpResponse<VProducto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (idProducto != null) __params = __params.set('idProducto', idProducto.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/vProducto`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VProducto>;
      }),
    );
  }
  /**
   * Obtener vProducto
   * @param idProducto undefined
   * @return OK
   */
  vProductoObtener(idProducto: string): __Observable<VProducto> {
    return this.vProductoObtenerResponse(idProducto).pipe(__map((_r) => _r.body as VProducto));
  }

  /**
   * Consultar lista paginada de vProducto
   * @param info Filtros y ordenamientos
   * @return OK
   */
  vProductoQueryResultResponse(
    info: QueryInfo,
  ): __Observable<__StrictHttpResponse<QueryResultVProducto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = info;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/vProducto`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<QueryResultVProducto>;
      }),
    );
  }
  /**
   * Consultar lista paginada de vProducto
   * @param info Filtros y ordenamientos
   * @return OK
   */
  vProductoQueryResult(info: QueryInfo): __Observable<QueryResultVProducto> {
    return this.vProductoQueryResultResponse(info).pipe(
      __map((_r) => _r.body as QueryResultVProducto),
    );
  }

  /**
   * Obtener vProductoClienteVD
   * @param params The `ConfiguracionProductosService.VProductoClienteVDObtenerParams` containing the following parameters:
   *
   * - `idProducto`:
   *
   * - `IdCliente`:
   *
   * - `IdDireccionEntrega`:
   *
   * @return OK
   */
  vProductoClienteVDObtenerResponse(
    params: ConfiguracionProductosService.VProductoClienteVDObtenerParams,
  ): __Observable<__StrictHttpResponse<VProductoClienteVDDetalle>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.idProducto != null)
      __params = __params.set('idProducto', params.idProducto.toString());
    if (params.IdCliente != null) __params = __params.set('IdCliente', params.IdCliente.toString());
    if (params.IdDireccionEntrega != null)
      __params = __params.set('IdDireccionEntrega', params.IdDireccionEntrega.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/vProductoClienteVD`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VProductoClienteVDDetalle>;
      }),
    );
  }
  /**
   * Obtener vProductoClienteVD
   * @param params The `ConfiguracionProductosService.VProductoClienteVDObtenerParams` containing the following parameters:
   *
   * - `idProducto`:
   *
   * - `IdCliente`:
   *
   * - `IdDireccionEntrega`:
   *
   * @return OK
   */
  vProductoClienteVDObtener(
    params: ConfiguracionProductosService.VProductoClienteVDObtenerParams,
  ): __Observable<VProductoClienteVDDetalle> {
    return this.vProductoClienteVDObtenerResponse(params).pipe(
      __map((_r) => _r.body as VProductoClienteVDDetalle),
    );
  }

  /**
   * Consultar lista paginada de vProductoClienteVD
   * @param info Filtros y ordenamientos
   * @return OK
   */
  vProductoClienteVDQueryResultResponse(
    info: QueryInfo,
  ): __Observable<__StrictHttpResponse<QueryResultVProductoClienteVD>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = info;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/vProductoClienteVD`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<QueryResultVProductoClienteVD>;
      }),
    );
  }
  /**
   * Consultar lista paginada de vProductoClienteVD
   * @param info Filtros y ordenamientos
   * @return OK
   */
  vProductoClienteVDQueryResult(info: QueryInfo): __Observable<QueryResultVProductoClienteVD> {
    return this.vProductoClienteVDQueryResultResponse(info).pipe(
      __map((_r) => _r.body as QueryResultVProductoClienteVD),
    );
  }

  /**
   * Consultar lista paginada de vProductoClienteVD
   * @param params The `ConfiguracionProductosService.VProductoClienteVDVProductoClienteVDByClienteParams` containing the following parameters:
   *
   * - `info`: Filtros y ordenamientos
   *
   * - `IdDireccionEntrega`:
   *
   * - `IdCliente`: Filtrar por Cliente
   *
   * @return OK
   */
  vProductoClienteVDVProductoClienteVDByClienteResponse(
    params: ConfiguracionProductosService.VProductoClienteVDVProductoClienteVDByClienteParams,
  ): __Observable<__StrictHttpResponse<QueryResultVProductoClienteVDDetalle>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.info;
    if (params.IdDireccionEntrega != null)
      __params = __params.set('IdDireccionEntrega', params.IdDireccionEntrega.toString());
    if (params.IdCliente != null) __params = __params.set('IdCliente', params.IdCliente.toString());
    let req = new HttpRequest<any>('POST', this.rootUrl + `/vProductoClienteVDByCliente`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<QueryResultVProductoClienteVDDetalle>;
      }),
    );
  }
  /**
   * Consultar lista paginada de vProductoClienteVD
   * @param params The `ConfiguracionProductosService.VProductoClienteVDVProductoClienteVDByClienteParams` containing the following parameters:
   *
   * - `info`: Filtros y ordenamientos
   *
   * - `IdDireccionEntrega`:
   *
   * - `IdCliente`: Filtrar por Cliente
   *
   * @return OK
   */
  vProductoClienteVDVProductoClienteVDByCliente(
    params: ConfiguracionProductosService.VProductoClienteVDVProductoClienteVDByClienteParams,
  ): __Observable<QueryResultVProductoClienteVDDetalle> {
    return this.vProductoClienteVDVProductoClienteVDByClienteResponse(params).pipe(
      __map((_r) => _r.body as QueryResultVProductoClienteVDDetalle),
    );
  }

  /**
   * Obtener vProductoClienteWeb
   * @param params The `ConfiguracionProductosService.VProductoClienteWebObtenerParams` containing the following parameters:
   *
   * - `idProducto`:
   *
   * - `IdCliente`:
   *
   * - `IdDireccionEntrega`:
   *
   * @return OK
   */
  vProductoClienteWebObtenerResponse(
    params: ConfiguracionProductosService.VProductoClienteWebObtenerParams,
  ): __Observable<__StrictHttpResponse<VProductoClienteWebDetalle>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.idProducto != null)
      __params = __params.set('idProducto', params.idProducto.toString());
    if (params.IdCliente != null) __params = __params.set('IdCliente', params.IdCliente.toString());
    if (params.IdDireccionEntrega != null)
      __params = __params.set('IdDireccionEntrega', params.IdDireccionEntrega.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/vProductoClienteWeb`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VProductoClienteWebDetalle>;
      }),
    );
  }
  /**
   * Obtener vProductoClienteWeb
   * @param params The `ConfiguracionProductosService.VProductoClienteWebObtenerParams` containing the following parameters:
   *
   * - `idProducto`:
   *
   * - `IdCliente`:
   *
   * - `IdDireccionEntrega`:
   *
   * @return OK
   */
  vProductoClienteWebObtener(
    params: ConfiguracionProductosService.VProductoClienteWebObtenerParams,
  ): __Observable<VProductoClienteWebDetalle> {
    return this.vProductoClienteWebObtenerResponse(params).pipe(
      __map((_r) => _r.body as VProductoClienteWebDetalle),
    );
  }

  /**
   * Consultar lista paginada de vProductoClienteWeb
   * @param info Filtros y ordenamientos
   * @return OK
   */
  vProductoClienteWebQueryResultResponse(
    info: QueryInfo,
  ): __Observable<__StrictHttpResponse<QueryResultVProductoClienteWeb>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = info;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/vProductoClienteWeb`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<QueryResultVProductoClienteWeb>;
      }),
    );
  }
  /**
   * Consultar lista paginada de vProductoClienteWeb
   * @param info Filtros y ordenamientos
   * @return OK
   */
  vProductoClienteWebQueryResult(info: QueryInfo): __Observable<QueryResultVProductoClienteWeb> {
    return this.vProductoClienteWebQueryResultResponse(info).pipe(
      __map((_r) => _r.body as QueryResultVProductoClienteWeb),
    );
  }

  /**
   * Consultar lista paginada de vProductoClienteWeb
   * @param params The `ConfiguracionProductosService.VProductoClienteWebQueryResult_1Params` containing the following parameters:
   *
   * - `info`: Filtros y ordenamientos
   *
   * - `IdDireccionEntrega`:
   *
   * - `IdCliente`:
   *
   * @return OK
   */
  vProductoClienteWebQueryResult_1Response(
    params: ConfiguracionProductosService.VProductoClienteWebQueryResult_1Params,
  ): __Observable<__StrictHttpResponse<QueryResultVProductoClienteWebDetalle>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.info;
    if (params.IdDireccionEntrega != null)
      __params = __params.set('IdDireccionEntrega', params.IdDireccionEntrega.toString());
    if (params.IdCliente != null) __params = __params.set('IdCliente', params.IdCliente.toString());
    let req = new HttpRequest<any>('POST', this.rootUrl + `/vProductoClienteWebByCliente`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<QueryResultVProductoClienteWebDetalle>;
      }),
    );
  }
  /**
   * Consultar lista paginada de vProductoClienteWeb
   * @param params The `ConfiguracionProductosService.VProductoClienteWebQueryResult_1Params` containing the following parameters:
   *
   * - `info`: Filtros y ordenamientos
   *
   * - `IdDireccionEntrega`:
   *
   * - `IdCliente`:
   *
   * @return OK
   */
  vProductoClienteWebQueryResult_1(
    params: ConfiguracionProductosService.VProductoClienteWebQueryResult_1Params,
  ): __Observable<QueryResultVProductoClienteWebDetalle> {
    return this.vProductoClienteWebQueryResult_1Response(params).pipe(
      __map((_r) => _r.body as QueryResultVProductoClienteWebDetalle),
    );
  }
}

module ConfiguracionProductosService {
  /**
   * Parameters for ProductoGuardarOActualizar
   */
  export interface ProductoGuardarOActualizarParams {
    /**
     * Producto
     */
    Producto: Producto;

    /**
     * Identificador de Movimiento
     */
    IdMovimiento?: string;
  }

  /**
   * Parameters for ProductoClienteWebObtener
   */
  export interface ProductoClienteWebObtenerParams {
    idProducto: string;
    IdCliente: string;
    IdDireccionEntrega?: string;
  }

  /**
   * Parameters for ProductoClienteWebQueryResult_1
   */
  export interface ProductoClienteWebQueryResult_1Params {
    /**
     * Filtros y ordenamientos
     */
    info: QueryInfo;
    IdDireccionEntrega: string;
    IdCliente: string;
  }

  /**
   * Parameters for vProductoClienteVDObtener
   */
  export interface VProductoClienteVDObtenerParams {
    idProducto: string;
    IdCliente: string;
    IdDireccionEntrega?: string;
  }

  /**
   * Parameters for vProductoClienteVDVProductoClienteVDByCliente
   */
  export interface VProductoClienteVDVProductoClienteVDByClienteParams {
    /**
     * Filtros y ordenamientos
     */
    info: QueryInfo;
    IdDireccionEntrega: string;

    /**
     * Filtrar por Cliente
     */
    IdCliente: string;
  }

  /**
   * Parameters for vProductoClienteWebObtener
   */
  export interface VProductoClienteWebObtenerParams {
    idProducto: string;
    IdCliente: string;
    IdDireccionEntrega?: string;
  }

  /**
   * Parameters for vProductoClienteWebQueryResult_1
   */
  export interface VProductoClienteWebQueryResult_1Params {
    /**
     * Filtros y ordenamientos
     */
    info: QueryInfo;
    IdDireccionEntrega: string;
    IdCliente: string;
  }
}

export {ConfiguracionProductosService};
