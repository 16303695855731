import {MINIO_SERVER_URLS, SERVER_URLS} from '@env/baseURL';
import * as packageJson from 'package.json';

const packageVersion = JSON.stringify(packageJson);

export const environment = {
  apiPort: 442,
  apiUrl: SERVER_URLS.ventaDigitalApiDev,
  appName: 'ProquifaNet 2 Venta Digital',
  appVersion: JSON.parse(packageVersion)?.version,
  environmentServerName: 'dev',
  i18nPrefix: '',
  identityPort: 9002,
  identityUrl: SERVER_URLS.ventaDigitalIdentityDev,
  production: false,
};

export const minioSettings = {
  accessKey: 'GEpF1O4XFiblnc8B',
  host: MINIO_SERVER_URLS.ventaDigitalDev,
  port: 9000,
  secretKey: '7IP1UH35TdNaBhCckeDitVAizHl7I3N8',
  useSSL: true,
};
