/* tslint:disable */
import {Injectable} from '@angular/core';
import {HttpClient, HttpRequest, HttpResponse, HttpHeaders} from '@angular/common/http';
import {BaseService as __BaseService} from '../base-service';
import {ApiCatalogosConfiguration as __Configuration} from '../api-catalogos-configuration';
import {StrictHttpResponse as __StrictHttpResponse} from '../strict-http-response';
import {Observable as __Observable} from 'rxjs';
import {map as __map, filter as __filter} from 'rxjs/operators';

import {QueryResultVBitacoraPromsyObj} from '../models/query-result-vbitacora-promsy-obj';
import {ResumeGroupQueryInfo} from '../models/resume-group-query-info';
import {ListUsersObj} from '../models/list-users-obj';
@Injectable({
  providedIn: 'root',
})
class ConfiguracionProductosBitacoraService extends __BaseService {
  static readonly BitacoraProductoBitacoraProductoPath = '/BitacoraProducto';
  static readonly BitacoraProductoGetFilterUserPath = '/ObtenerFiltroUsuarioBitacora';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * BitacoraProducto BitacoraProducto
   * @param info undefined
   * @return OK
   */
  BitacoraProductoBitacoraProductoResponse(
    info: ResumeGroupQueryInfo,
  ): __Observable<__StrictHttpResponse<QueryResultVBitacoraPromsyObj>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = info;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/BitacoraProducto`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<QueryResultVBitacoraPromsyObj>;
      }),
    );
  }
  /**
   * BitacoraProducto BitacoraProducto
   * @param info undefined
   * @return OK
   */
  BitacoraProductoBitacoraProducto(
    info: ResumeGroupQueryInfo,
  ): __Observable<QueryResultVBitacoraPromsyObj> {
    return this.BitacoraProductoBitacoraProductoResponse(info).pipe(
      __map((_r) => _r.body as QueryResultVBitacoraPromsyObj),
    );
  }

  /**
   * GetFilterUser BitacoraProducto
   * @param info undefined
   * @return OK
   */
  BitacoraProductoGetFilterUserResponse(
    info: ResumeGroupQueryInfo,
  ): __Observable<__StrictHttpResponse<ListUsersObj>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = info;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/ObtenerFiltroUsuarioBitacora`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListUsersObj>;
      }),
    );
  }
  /**
   * GetFilterUser BitacoraProducto
   * @param info undefined
   * @return OK
   */
  BitacoraProductoGetFilterUser(info: ResumeGroupQueryInfo): __Observable<ListUsersObj> {
    return this.BitacoraProductoGetFilterUserResponse(info).pipe(
      __map((_r) => _r.body as ListUsersObj),
    );
  }
}

module ConfiguracionProductosBitacoraService {}

export {ConfiguracionProductosBitacoraService};
