import {createSelector} from '@ngrx/store';

import {AuthState} from '@appModels/store/auth/auth.models';
import {selectAuthState} from '@appCore/core.state';
import {UserInfo} from '@appModels/auth/user-info.model';
import {ENUM_USER_FUNCTIONS} from '@appUtil/common.protocols';

export const selectAuth = createSelector(selectAuthState, (state: AuthState) => state);

export const selectIsAuthenticated = createSelector(
  selectAuth,
  (state: AuthState) => state.isAuthenticated,
);

export const selectToken = createSelector(selectAuth, (state: AuthState) => state.token);
export const selectReturnUrl = createSelector(selectAuth, (state: AuthState) => state.returnUrl);

export const selectUser = createSelector(
  selectAuth,
  (state: AuthState): UserInfo => state.userInfo,
);
export const selectIdUser = createSelector(selectUser, (state: UserInfo) => state.IdUsuario);

export const selectUserRoles = createSelector(selectUser, (state: UserInfo) => state.Roles);

export const selectUserFunctions = createSelector(
  selectUser,
  (state: UserInfo): Array<string> => state.Funciones,
);
export const selectHasFunctionAnalistaDeCuentasPorCobrar = createSelector(
  selectUserFunctions,
  (functions: Array<string>): boolean =>
    functions?.includes(ENUM_USER_FUNCTIONS.functionAnalistaDeCuentasPorCobrar),
);
export const selectHasFunctionComprador = createSelector(
  selectUserFunctions,
  (functions: Array<string>): boolean => functions?.includes(ENUM_USER_FUNCTIONS.functionComprador),
);
export const selectHasFunctionCoordinadorDeServicioAlCliente = createSelector(
  selectUserFunctions,
  (functions: Array<string>): boolean =>
    functions?.includes(ENUM_USER_FUNCTIONS.functionCoordinadorDeServicioAlCliente),
);
export const selectHasFunctionCoordinadorDeVentaInterna = createSelector(
  selectUserFunctions,
  (functions: Array<string>): boolean =>
    functions?.includes(ENUM_USER_FUNCTIONS.functionCoordinadorDeVentaInterna),
);
export const selectHasFunctionESAC = createSelector(
  selectUserFunctions,
  (functions: Array<string>): boolean => functions?.includes(ENUM_USER_FUNCTIONS.functionEsac),
);
export const selectHasFunctionEVI = createSelector(
  selectUserFunctions,
  (functions: Array<string>): boolean => functions?.includes(ENUM_USER_FUNCTIONS.functionEvi),
);
export const selectHasFunctionEve = createSelector(
  selectUserFunctions,
  (functions: Array<string>): boolean => functions?.includes(ENUM_USER_FUNCTIONS.functionEve),
);
export const selectHasFunctionSuper = createSelector(
  selectUserFunctions,
  (functions: Array<string>): boolean => functions?.includes(ENUM_USER_FUNCTIONS.functionSuper),
);
export const selectHasFunctionVisitante = createSelector(
  selectUserFunctions,
  (functions: Array<string>): boolean => functions?.includes(ENUM_USER_FUNCTIONS.functionVisitante),
);
